* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  align-items: auto;
  padding: auto;
}

.App {
  text-align: center;
}
