.footer{
  padding: 4rem 6rem;
  background-color: #000;
  color: #fff
}

.top{
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top i{
  color: #fff;
  font-size: 2rem;
  margin-left: 1rem;
}

.top i:hover{
  color: grey
}

.bottom{
  padding-top: 2rem;
  text-align: start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bottom div{
  display: flex;
  flex-direction: column;
}

.bottom h4{
  font-size: 1.3rem;
  padding: 1rem 0 .8rem 0;
}

.bottom a{
  text-decoration: none;
  color: #fafafa;
  padding-bottom: 0.4rem;
  font-size: 1.1rem;
}

@media screen and (max-width:850px){
  .footer{
    padding: 2rem 4rem;
  }

  .footer i{
    margin: 1rem 1rem 0 0;
  }

  .bottom div{
    width: 50%;
  }
}