@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,900;1,100;1,200;1,400;1,500;1,900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar-logo{
  color: #222;
  font-size: 2rem;
  cursor: pointer;
}

.nav-menu{
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
}
.nav-links{
  text-decoration: none;
  color: #222;
  font-size: 1.2rem;
  font-weight:  600;
  padding:  0.7rem .07rem;
  white-space: nowrap;
}
.nav-links i{
  padding-right: 10px;
  padding-left: 10px;

}
.nav-links:hover{
  background-color: #01959a;
  color: #fff;
  border-radius: 8px;
  padding-right: 10px;
  transition: all 0.2s ease-in-out;
}
.NavbarItems{
  background-color: #fff;
    display: flex;
    flex: content;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
}
.nav-links-mobile{
    display: none;
}
button{
    padding: 0.5rem 0.5rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
button:hover{
    background-color: #151516;
    color: #fff;

}
.menu-icons{
    display: none;
}

@media screen and (max-width: 850px){
    .NavbarItems{
        z-index: 999;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #fff;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0%;
        left: 100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;
      }
      .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
        position: absolute;

      }
      .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
      }
      .nav-links:hover{ 
        background-color: #01959a;
      }
      button{
        display: none;  
      }
      .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto;
        border-radius: 4px;
        width: 90%;
        background: #01959a;
        text-decoration: none;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
      }
      .menu-icons{
        display: block;
        cursor: pointer;
      }
      .menu-icons i{
        font-size: 1.2rem;
        color:#222;
      }
}